<template>
  <ExtendedDialog
    :show-dialog="show"
    :config="changeAccountDialog"
    :main-action-active="selectedAccounts.length > 0"
    @execute-action="execute($event)"
  >
    <div class="dialog-content">
      <ExtendedDataTable
        :loading="loading"
        :headers="headers"
        :items="getAccounts"
        :config="changeAccountTableConfig"
        show-select
        select-strategy="single"
        custom-height="50vh"
        @update:selected="selectedAccounts = $event"
      />
    </div>
  </ExtendedDialog>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { mapState, mapActions } from 'pinia';
import { authenticationStore } from '@/store/pinia/AuthenticationStore';
import { ButtonCallbacks } from '@/components/types/Dialog';
import ExtendedDialog from '@/components/common/dialog/ExtendedDialog.vue';
import { Account } from '@/store/types/identity';
import { changeAccountDialog } from '@/models/settings/settingsDialogConfig';
import { changeAccountTableConfig } from '@/models/admin/accountTableConfig';
import { DataTableHeader } from '@/components/types/DataTable';
import ExtendedDataTable from '@/components/common/ExtendedDataTable.vue';
import { accountStore } from '@/store/pinia/AccountStore';

export default defineComponent({
  name: 'ChangeAccountDialog',
  components: { ExtendedDialog, ExtendedDataTable },
  props: {
    showDialog: {
      type: Boolean,
      default: true
    }
  },
  emits: ['update:showDialog'],
  data() {
    return {
      selectedAccounts: [] as Account[],
      showNoSelectedAlert: false,
      changeAccountDialog,
      changeAccountTableConfig,
      loading: false
    };
  },
  computed: {
    ...mapState(accountStore, ['accounts']),
    ...mapState(authenticationStore, ['loggedInAccount']),

    show: {
      get() {
        return this.showDialog;
      },
      set(value) {
        this.$emit('update:showDialog', value);
      }
    },
    headers(): DataTableHeader<Account>[] {
      return [
        {
          title: this.$t('administration.accountName').toString(),
          value: 'name',
          width: '45%'
        },
        {
          title: this.$t('administration.instanceOrNamespace').toString(),
          value: 'namespace',
          width: '45%'
        }
      ];
    },
    getAccounts(): Account[] {
      return this.accounts.filter(
        (account: Account) => account.uuid !== this.loggedInAccount.uuid
      );
    }
  },
  mounted() {
    this.loading = true;
    void this.fetchAccounts();
    this.loading = false;
  },
  methods: {
    ...mapActions(accountStore, ['fetchAccounts']),
    ...mapActions(authenticationStore, ['switchAccount']),
    async execute(callbackID) {
      if (callbackID === ButtonCallbacks.SAVE) {
        await this.switchAccount(this.selectedAccounts[0].name);
      }
      this.closeDialog();
    },
    closeDialog() {
      this.$emit('update:showDialog', false);
      this.selectedAccounts = [];
      this.showNoSelectedAlert = false;
    }
  }
});
</script>
