export const DEPLOYMENT_TARGET_TYPE_MANAGED_DEVICES = 'MANAGED_DEVICES';
export const DEPLOYMENT_TARGET_TYPE_DEVICE_GROUPS = 'DEVICE_GROUPS';
export const DEPLOYMENT_TARGET_TYPE_ALL = 'ALL';

export const DEPLOYMENT_METHOD_AUTO_UPDATE = 'AUTO_UPDATE';
export const DEPLOYMENT_METHOD_UPDATE_JOB = 'UPDATE_JOB';

export const DEPLOYMENT_SUBJECT_UPDATE_PACKET = 'UPDATE_PACKET';
export const DEPLOYMENT_SUBJECT_CLI_TEMPLATE = 'CLI_TEMPLATE';

export const DEPLOYMENT_UPDATE_JOB_STATUS_READY = 'READY';
export const DEPLOYMENT_UPDATE_JOB_STATUS_SCHEDULED = 'SCHEDULED';
export const DEPLOYMENT_UPDATE_JOB_STATUS_RUNNING = 'RUNNING';
export const DEPLOYMENT_UPDATE_JOB_STATUS_SUSPENDED = 'SUSPENDED';
export const DEPLOYMENT_UPDATE_JOB_STATUS_COMPLETED = 'COMPLETED';
export const DEPLOYMENT_UPDATE_JOB_STATUS_CANCELED = 'CANCELED';
export const DEPLOYMENT_UPDATE_JOB_STATUS_FAILED = 'FAILED';

export const UPDATE_JOB_PROGRESS_READY = 'READY';
export const UPDATE_JOB_PROGRESS_RUNNING = 'RUNNING';
export const UPDATE_JOB_PROGRESS_SUCCEEDED = 'SUCCEEDED';
export const UPDATE_JOB_PROGRESS_PARTIALLY_SUCCEEDED =
  'PARTIALLY_SUCCEEDED';
export const UPDATE_JOB_PROGRESS_FAILED = 'FAILED';

export const UPDATE_JOB_DEVICE_PROGRESS_RETRYING = 'RETRYING';
export const UPDATE_JOB_DEVICE_PROGRESS_SUBMITTING = 'SUBMITTING';
export const UPDATE_JOB_DEVICE_PROGRESS_SOFTWARE_UPDATE = 'SOFTWAREUPDATE';
export const UPDATE_JOB_DEVICE_PROGRESS_RUNNING = 'RUNNING';
export const UPDATE_JOB_DEVICE_PROGRESS_SUCCEEDED = 'SUCCEEDED';
export const UPDATE_JOB_DEVICE_PROGRESS_FAILED = 'FAILED';
export const UPDATE_JOB_DEVICE_PROGRESS_TIMEOUT = 'TIMEOUT';
export const UPDATE_JOB_DEVICE_PROGRESS_UNKNOWN = 'UNKNOWN';

export const UPDATE_JOB_DEVICE_RESULT_SUCCESS = 'UPDATED_SUCCESS';
export const UPDATE_JOB_DEVICE_RESULT_IGNORED = 'UPDATED_IGNORED';
export const UPDATE_JOB_DEVICE_RESULT_ERROR = 'UPDATED_ERROR';
export const UPDATE_JOB_DEVICE_RESULT_ERROR_UNIMPLEMENTED = 'UPDATED_ERROR_UNIMPLEMENTED';
export const UPDATE_JOB_DEVICE_RESULT_ERROR_BUSY = 'UPDATED_ERROR_BUSY';
export const UPDATE_JOB_DEVICE_RESULT_ERROR_DISK_SPACE = 'UPDATED_ERROR_DISK_SPACE';
export const UPDATE_JOB_DEVICE_RESULT_ERROR_FILE_CORRUPT = 'UPDATED_ERROR_FILE_CORRUPT';
export const UPDATE_JOB_DEVICE_RESULT_ERROR_BASE_VERSION_MISSING = 'UPDATED_ERROR_BASE_VERSION_MISSING';
export const UPDATE_JOB_DEVICE_RESULT_ERROR_DECRYPTION = 'UPDATED_ERROR_DECRYPTION';
export const UPDATE_JOB_DEVICE_RESULT_ERROR_MANIFEST_MISMATCH = 'UPDATED_ERROR_MANIFEST_MISMATCH';
export const UPDATE_JOB_DEVICE_RESULT_ERROR_KERNEL_FAILED = 'UPDATED_ERROR_KERNEL_FAILED';
export const UPDATE_JOB_DEVICE_RESULT_ERROR_FILESYSTEM = 'UPDATED_ERROR_FILESYSTEM';
export const UPDATE_JOB_DEVICE_RESULT_ERROR_DOWNLOAD = 'UPDATED_ERROR_DOWNLOAD';
export const UPDATE_JOB_DEVICE_RESULT_ERROR_TIMEOUT = 'UPDATED_ERROR_TIMEOUT';
