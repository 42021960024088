import { SubjectType } from '@/shared/updateWizardSteps';
import { UpdatePacketType } from '@/store/types';

export * from './UpdateJobs';
export * from './UpdateWizard';
export * from './UpdateServers';

export interface EditDeploymentSubjectStateType {
  type: SubjectType;
  insysUpdatePackets: UpdatePacketType[];
  alertText: string;
  selectedUpdatePacket: UpdatePacketType | null;
  alertNoPacket: boolean;
  updatePacketId: string;
  selectedUpdatePacketFlavor: string;
}

export interface EditDeploymentSubjectActionType {
  commit: (mutation: string, payload?: any) => object;
  dispatch: (action: string, payload?: any) => Promise<any>;
  state: EditDeploymentSubjectStateType;
}
