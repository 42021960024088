/* eslint-disable prefer-arrow/prefer-arrow-functions */
import Api from '@/services/ApiService';
import type { AxiosRequestConfig } from 'axios';

export default {
  getSoftwareList() {
    return Api.get(
      '/current-update-packets.json',
      {
        headers: {
          Authorization: ''
        }
      },
      import.meta.env.VITE_AUTO_UPDATE_SERVER_URL
    );
  },
  downloadUpdatePacket(url: string) {
    const config: AxiosRequestConfig = {
      responseType: 'blob'
    };
    return Api.get(url, config, import.meta.env.VITE_AUTO_UPDATE_SERVER_URL);
  }

};
