/* eslint-disable prefer-arrow/prefer-arrow-functions */

import Vuex from 'vuex';
import app from './modules/app';
import certificateAuthorities from './modules/certificateAuthorities';
import certificates from './modules/certificates';
import updateWizard from './modules/updateWizard';
import updatePackages from './modules/updatePackages';
import updateJobs from './modules/updateJobs';
import editDeploymentSubject from './modules/editDeploymentSubject';
import updateServer from './modules/updateServer';
import apiToken from '@/store/modules/apiToken';
import systemSettings from '@/store/modules/systemSettings';
import auditLogs from '@/store/modules/auditLogs';
import templates from '@/store/modules/templates';

const store = new Vuex.Store({
  modules: {
    app,
    certificates,
    updateWizard,
    updateServer,
    updatePackages,
    updateJobs,
    editDeploymentSubject,
    apiToken,
    systemSettings,
    auditLogs,
    templates,
    certificateAuthorities
  }
} as any);

export default store;

const initialStateCopy = JSON.parse(JSON.stringify(store.state));

export function resetState() {
  store.replaceState(JSON.parse(JSON.stringify(initialStateCopy)));
}
