export * from './Device';

export interface EventLogType extends Record<string, unknown> {
  uuid: string;
  createdAt: string;
  updatedAt: string;
  managedDeviceUuid: string;
  eventCategory: string;
  severity: string;
  event: string;
  timestamp: string;
}

export interface TemplateStateType {
  loading: boolean;
  snackbarStatus: boolean;
  cliTemplates: CliTemplateType[];
  cliTemplate: CliTemplateType;
  selectedCliTemplates: CliTemplateType[];
  visibleCliTemplates: CliTemplateType[];
  visibilityFilter: boolean;
  search: string;
}

export interface TemplateActionType {
  commit: (mutation: string, payload?: any) => object;
  dispatch: (action: string, payload?: any) => Promise<any>;
  state: TemplateStateType;
}

export interface CliTemplateDeploymentType {
  uuid: string;
  name: string;
  deploymentMethod: string;
}
// tooof
export interface CliTemplateType extends Record<string, unknown> {
  uuid: string;
  createdAt?: string;
  updatedAt?: string;
  name: string;
  status?: string;
  tags?: string[] | null;
  content: string[];
  isComplex?: boolean;
  keysConfiguration: KeysConfiguration[];
  linkedDeployments?: CliTemplateDeploymentType[];
}

export interface InternalProperties {
  identifier: string;
  reference?: string;
}

export interface KeysConfiguration {
  key: string;
  valueType: string;
  isDisabled?: boolean;
  isDeleted?: boolean;
  deletedAt?: number;
  isValid?: boolean;
  internalProperties?: InternalProperties;
}

export interface DryRunCliTemplateType {
  deviceUuid: string;
  uuid: string;
  name: string;
  values: any;
}

export interface CliTemplateTypeDeleteContainerType {
  data: CliTemplateType;
  keyToRevert?: string;
  index: number;
  softDelete?: boolean;
}

export enum IpIntersection {
  EQUAL,
  NETWORK_INTERSECTION,
  NO_INTERSECTION
}
export interface QuickStartWanTypeConfigs {
  LTE: {
    pin?: string;
    apn?: string;
  };
  // DSL: {
  //    TODO
  // };
  // ...
}

export type QuickStartWan<
  T extends keyof QuickStartWanTypeConfigs = keyof QuickStartWanTypeConfigs
> = {
  type: T;
} & {
  [K in T]?: QuickStartWanTypeConfigs[K];
};

export interface QuickStartParams {
  apiVersion: number;
  asciiOnly?: boolean;
  spec: {
    wan?: QuickStartWan;
    lan?: {
      ip: string;
      netmask: number;
      dhcp?: {
        startip: string;
        endip: string;
        netmask?: number;
      };
    };
    system: {
      login: {
        username: string;
        password: string;
      };
    };
    services: {
      routerManagement: {
        deviceName: string;
        serialNumber: string;
        groupUuid?: string;
        location?: string;
        connect: boolean;
      };
      icsVpn?: { password: string; netmappingIp?: string };
    };
  };
}

export enum FirmwareAutoUpdate {
  ACTIVATE = 1,
  DEACTIVATE = 2
}
