import ApiService from '@/services/ApiService';
import { TokenService } from './TokenService';
import { showErrorSnackbar, createErrorMessage } from '../../util/snackBarUtil';
import {
  UserLoginType,
  AuthenticationError,
  SignupDataType,
  ConfirmationError
} from '@/store/types/auth';
import { authenticationStore } from '@/store/pinia/AuthenticationStore';
import RefreshService from '@/services/RefreshService';
import router from '@/router';

const configToAuthFrontend = {
  auth: {
    username: (import.meta.env.VITE_CLIENT_ID as string) || '',
    password: (import.meta.env.VITE_CLIENT_SECRET as string) || ''
  }
};

const AuthService = {
  login: async (user: UserLoginType) => {
    const loginURL = import.meta.env.VITE_API_LOGIN_URL as string;
    const requestData = {
      username: user.username,
      password: user.password
    };

    try {
      const response = await ApiService.post(
        '',
        requestData,
        configToAuthFrontend,
        loginURL
      );
      TokenService.saveAccessToken(response.data.access);
      TokenService.saveRefreshToken(response.data.refresh);
      return response.data.access;
    } catch (error) {
      throw new AuthenticationError(
        error.response.status,
        error.response.data.message
      );
    }
  },

  signup: async (signupData: SignupDataType): Promise<string> => {
    const signUpURL = import.meta.env.VITE_API_SIGNUP_URL as string;
    const response = await ApiService.post(
      '',
      signupData,
      configToAuthFrontend,
      signUpURL
    );
    TokenService.saveAccessToken(response.data.access);
    TokenService.saveRefreshToken(response.data.refresh);
    return response.data.access;
  },

  // Not really used! Only used in QuickstartWizard, not testable,
  checkPassword: async (password: string) => {
    const checkPasswordURL = import.meta.env
      .VITE_API_CHECK_PASSWORD_URL as string;
    const passwordDto = { password: password ?? '' };
    let response = { data: { passwordConfirmed: false } };
    if (!password) {
      return response.data;
    }
    try {
      // auth?
      response = await ApiService.post('', passwordDto, {}, checkPasswordURL);
    } catch (error) {
      showErrorSnackbar(createErrorMessage(error));
    }
    return response.data;
  },

  logout: async () => {
    const logoutURL = import.meta.env.VITE_API_LOGOUT_URL as string;
    const requestData = {
      access: TokenService.getAccessToken(),
      refresh: TokenService.getRefreshToken()
    };

    try {
      await ApiService.post('', requestData, configToAuthFrontend, logoutURL);
      TokenService.removeAccessToken();
      TokenService.removeRefreshToken();
      await router.push('/login');
    } catch (error) {
      await RefreshService.cleanUpAndRedirectToLogin();
      throw new AuthenticationError(
        error.response.status,
        error.response.data.message
      );
    }
  },

  confirm: async (confirmationCode: string) => {
    const confirmURL = `${import.meta.env.VITE_AUTH_URL}/signup/confirm/${confirmationCode}`;
    try {
      await ApiService.put('', {}, configToAuthFrontend, confirmURL);
      const authStore = authenticationStore();
      authStore.hideConfirmUserBar();
    } catch (error) {
      throw new ConfirmationError(error.status, error.data.message);
    }
  },

  requestConfirmationLink: async (userId: string) => {
    const requestConfirmationLinkURL = `${import.meta.env.VITE_AUTH_URL as string}/signup/request-confirmation/${userId}`;

    try {
      await ApiService.put(
        '',
        {},
        configToAuthFrontend,
        requestConfirmationLinkURL
      );
    } catch (error) {
      throw new ConfirmationError(error.status, error.data.message);
    }
  },

  sendResetPasswordLink: async (username: string) => {
    const requestConfirmationLinkURL = `${import.meta.env.VITE_AUTH_URL as string}/signup/reset-password/${username}`;
    try {
      await ApiService.post(
        '',
        {},
        configToAuthFrontend,
        requestConfirmationLinkURL
      );
    } catch {
      //
    }
  },

  resetPassword: async (resetPasswordCode: string, password: string) => {
    const resetPasswordURL = `${import.meta.env.VITE_AUTH_URL as string}/signup/reset-password/${resetPasswordCode}`;
    const requestData = {
      password: password
    };

    try {
      await ApiService.put(
        '',
        requestData,
        configToAuthFrontend,
        resetPasswordURL
      );
    } catch (error) {
      throw new AuthenticationError(error.status, error.data.message);
    }
  },

  switch: async (accountName: string): Promise<string> => {
    const switchURL = import.meta.env.VITE_API_SWITCH_URL as string;
    const requestData = {
      token: TokenService.getAccessToken(),
      account: accountName
    };

    try {
      const response = await ApiService.post(
        '',
        requestData,
        configToAuthFrontend,
        switchURL
      );
      TokenService.saveAccessToken(response.data.access);
      TokenService.saveRefreshToken(response.data.refresh);
      return response.data.access;
    } catch (error) {
      throw new AuthenticationError(
        error.response.status,
        error.response.data.message
      );
    }
  }
};

export default AuthService;
