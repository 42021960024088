export * from './rollouts';
export * from './autoupdate';
export * from './admin';
export * from './auth';
export * from './identity';
export * from './inventory';
export * from './certificates';

export interface UpdatePacketsType {
  updatePackets: UpdatePacketType[];
}

export interface UpdatePacketWarningType {
  language: string;
  text: string;
}

// TODO: WTF is the difference between this
// and the (same name) type defined in src/store/types/autoupdate/AutoUpdateServer.ts
// NAME THEM DIFFERENTLY! (Or omit the other one entirely and use a inline one, whatever...)
// And omit the stupid type suffix...
export interface UpdatePacketType extends Record<string, unknown> {
  uuid: string;
  createdAt?: string;
  updatedAt?: string;
  category?: string;
  description: string;
  objectPrefix: string;
  fileName: string;
  status?: string;
  hashSum: string;
  name?: string;
  preselectedFlavor?: string;
  warning?: UpdatePacketWarningType[];
  releaseNotesUrl?: string;
  flavors?: any;
  linkedDeployments?: UpdatePacketDeploymentType[];
  contents: UpdatePacketContentType[];
  fileTypes?: string[];
}

export interface UpdatePacketDeploymentType {
  uuid: string;
  name: string;
  deploymentMethod: string;
}

export interface UpdatePacketContentType {
  description: string;
  fileName: string;
  fileSize: string;
  fileType: string;
}

interface ResponseDeploymentDataType {
  count: number;
  members: any[];
  start: number;
  total: number;
}

export interface ResponseDeploymentType {
  data: ResponseDeploymentDataType;
}
export interface ServiceApiType<T> {
  find(): Promise<T>;
  create?(item: T): Promise<T>;
  update?(id: string, item: T): Promise<T>;
  getById(id: string): Promise<T>;
  getByIdOrDefault?(id: string): Promise<T>;
  deleteById?(id: string): Promise<boolean>;
  login?(username: string, password: string): Promise<T>;
}
export interface ServiceApiPayload {
  deploymentService: ServiceApiType<any>;
  updatePackageService: ServiceApiType<any>;
}
