<template>
  <v-select
    v-model="i18n.locale"
    variant="solo-filled"
    density="compact"
    hide-details
    prepend-inner-icon="mdi-translate"
    flat
    max-width="200px"
    data-cy="language-selector"
    :items="languageOptions"
    @update:model-value="localStorage.setItem('currentLang', $event)"
  />
</template>

<script lang="ts">
import { i18n } from '@/plugins/i18n';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'LanguageSelector',
  data() {
    return {
      languageOptions: [
        { title: 'English', value: 'en-GB' },
        { title: 'Deutsch', value: 'de-DE' }
      ],
      i18n,
      localStorage
    };
  }
});
</script>
