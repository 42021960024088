/* eslint-disable prefer-arrow/prefer-arrow-functions */
import Api from '@/services/ApiService';
import type { AxiosRequestConfig, AxiosResponse } from 'axios';
import { CertificateResponseType } from '@/store/types';
import { CertificateType } from '@/store/types/pki';
export default {
  find() {
    return Api.get('pki/certificates');
  },
  updateCertificate(
    certificate: CertificateType
  ): Promise<AxiosResponse<CertificateType>> {
    return Api.put(`/pki/certificates/${certificate.uuid}`, certificate);
  },
  import(
    file: File,
    name: string,
    password: string
  ): Promise<AxiosResponse<CertificateResponseType>> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('name', name);
    if (password && password !== '') {
      formData.append('password', password);
    }
    return Api.post('/pki/certificates/import', formData);
  },
  download(uuid: string) {
    const config: AxiosRequestConfig = {
      responseType: 'blob'
    };
    return Api.get(`/pki/certificates/${uuid}/download?format=pkcs12`, config);
  },
  delete(certificate: CertificateType) {
    return Api.delete(`/pki/certificates/${certificate.uuid}`);
  }
};
