<template>
  <v-dialog v-model="show" persistent scrollable width="120vh">
    <v-card class="rounded-corners" data-cy="multi-selection-dialog-content">
      <v-toolbar flat density="compact" class="pa-3" color="transparent">
        <v-toolbar-title class="bold-text">
          {{ title }}
        </v-toolbar-title>
        <v-btn
          class="ma-0 pa-0"
          icon
          data-cy="x-close-button"
          @click="show = false"
        >
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pa-0 ma-0">
        <ExtendedDataTable
          :items="selectedElements"
          :headers="headers"
          :config="tableConfig"
          :loading="false"
          :no-select="true"
          :outer-elements-height="27 + 60 + 180"
          class="px-8 pb-3"
        >
          <template v-for="(_, slotName) in $slots" #[slotName]="slotData">
            <slot :name="slotName" v-bind="slotData" />
          </template>
        </ExtendedDataTable>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-row class="mx-6 my-1" align="center">
          <v-btn
            color="primary"
            variant="outlined"
            class="text-none"
            @click="show = false"
          >
            {{ $t('common.cancel') }}
          </v-btn>
          <v-spacer />
          <template v-for="button in possibleActions" :key="button.id">
            <v-btn
              class="ml-3 text-none"
              variant="flat"
              :data-cy="'action-button-' + button.id"
              :color="button.multiSelectionDialogConfig?.color ?? 'primary'"
              @click="
                button.callback();
                show = false;
              "
            >
              <v-icon class="mr-1">{{ button.icon }}</v-icon>
              {{ $t(button.title) }}
            </v-btn>
          </template>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import {
  ButtonCallbacks,
  DataTable,
  DataTableHeader
} from '@/components/types/DataTable';
import { multiSelectionDialogTableConfig } from '@/models/inventory/deviceTableConfig';
import { ActionTypes, ActionsButtonAction } from '../../types/DataTable';

export default defineComponent({
  name: 'MultiSelectionDialog',
  props: {
    showDialog: {
      type: Boolean,
      required: true
    },
    config: {
      type: Object as () => DataTable,
      required: true
    },
    headers: {
      type: Array as () => DataTableHeader[],
      required: true
    },
    selectedElements: {
      type: Array as () => Record<string, unknown>[],
      required: true
    }
  },
  emits: ['update:showDialog'],
  data() {
    return {
      tableConfig: multiSelectionDialogTableConfig
    };
  },
  computed: {
    show: {
      get() {
        return this.showDialog;
      },
      set(value) {
        this.$emit('update:showDialog', value);
      }
    },
    title() {
      const translatedTitle = this.$t(
        this.config.toolbarConfig?.titleCounterConfig.title ?? 'common.elements'
      );
      return this.$t('common.elementsSelected', {
        count: this.selectedElements.length,
        name: translatedTitle
      });
    },
    possibleActions() {
      const actionsButtonConfig =
        this.config.toolbarConfig?.actionsButtonConfig ?? [];
      const isMultiSelectAction = (action: ActionsButtonAction) =>
        action.types.includes(ActionTypes.MULTIPLE);
      const hasFunction = (action: ActionsButtonAction) =>
        action.id !== ButtonCallbacks.NONE;
      const shouldBeVisible = (action: ActionsButtonAction) =>
        action.multiSelectionDialogConfig?.visible ?? true;
      return actionsButtonConfig
        .filter(
          (action) =>
            isMultiSelectAction(action) &&
            hasFunction(action) &&
            shouldBeVisible(action)
        )
        .sort(
          (a, b) =>
            (a.multiSelectionDialogConfig?.positionIndex ??
              actionsButtonConfig.length) -
            (b.multiSelectionDialogConfig?.positionIndex ??
              actionsButtonConfig.length)
        );
    }
  }
});
</script>
