<template>
  <v-app-bar
    data-cy="main-app-bar"
    :color="colors.topHeader"
    elevation="1"
    density="compact"
  >
    <router-link to="/devices">
      <v-img
        id="insys-icom-logo"
        class="mx-4"
        :src="$filters.assetUrl('assets/INSYS-icom-logo.svg')"
        height="48"
        width="95"
      />
    </router-link>
    <v-btn
      v-if="allowChangeAccount && notError"
      color="transparent"
      class="change-account-button text-none"
      data-cy="logged-in-show-change-account"
      @click="openChangeAccountDialog"
    >
      <v-icon color="white">mdi-swap-horizontal</v-icon>
      <span class="user-name-and-organisation">
        {{ $t('app.changeAccount') }}
      </span>
    </v-btn>
    <v-spacer />
    <v-img
    class="mt-1"
        :src="$filters.assetUrl('assets/icomRM-text.svg')"
        height="35"
        width="95"
      />
    <v-spacer />
    <!-- <QuickActionMenu
      v-if="loggedIn && notError"
      :get-license-features="licensedFeatures"
      :onpremises="onpremises"
      :settings="settings"
    /> -->
    <NotificationBell v-if="loggedIn && !onPremises" :logged-in="loggedIn" />
    <v-tooltip v-if="loggedIn" location="bottom" open-delay="100">
      <template #activator="{ props }">
        <v-btn
          id="menu-help-button"
          icon
          variant="text"
          color="white"
          :href="$t('administration.menuHelp')"
          target="_blank"
          class="rounded"
          v-bind="props"
        >
          <v-icon>mdi-help-circle-outline</v-icon>
        </v-btn>
      </template>
      <span>{{ $t('app.menuHelp') }}</span>
    </v-tooltip>
    <AppBarMenu
      v-if="loggedIn && notError"
      :title="userMenuWithLogoutCallback.title"
      :icon="showLicenseAlert ? 'mdi-account-alert' : 'mdi-account'"
      :icon-color="showLicenseAlert ? 'orange' : 'white'"
      :items="userMenuWithLogoutCallback.items"
      :licensed-features="licensedFeatures"
      :is-admin-account="isAdminAccount"
    >
      <template #top>
        <LanguageSelector class="px-4 pt-3" />
        <v-alert
          v-if="showLicenseAlert"
          type="warning"
          :text="getAlertText"
          variant="tonal"
        />
      </template>
      <template #button>
        <span class="user-name-and-organisation">
          {{ loggedInUser.username }}
          <br />
          {{ loggedInUser?.organisation?.name ?? '' }}
        </span>
      </template>
    </AppBarMenu>
    <ChangeAccountDialog
      v-if="showChangeAccountDialog"
      :show-dialog="showChangeAccountDialog"
      @update:show-dialog="showChangeAccountDialog = false"
    />
  </v-app-bar>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { colors } from '@/styles/colors';
import { mapActions, mapGetters } from 'vuex';
import { mapState, mapActions as mapActionsPinia } from 'pinia';
import { authenticationStore } from '@/store/pinia/AuthenticationStore';
import store from '@/store';
import AppBarMenu from './appBar/AppBarMenu.vue';
import { settingsMenu, userMenu } from './appBar/menus';
import LanguageSelector from '@/components/common/LanguageSelector.vue';
import ChangeAccountDialog from '@/components/app/appBar/ChangeAccountDialog.vue';
import { dateFormatter } from '@/util/dateFormatter';
import { i18n } from '@/plugins/i18n';
import NotificationBell from '@/components/app/appBar/Notifications/NotificationBell.vue';

export default defineComponent({
  name: 'AppBar',
  components: {
    AppBarMenu,
    LanguageSelector,
    ChangeAccountDialog,
    NotificationBell
  },
  data: () => ({
    colors,
    settingsMenu,
    userMenu,
    onpremises: store.getters.onpremises as boolean, // Vuex typing sucks
    showChangeAccountDialog: false,
    dateFormatter
  }),
  computed: {
    ...mapState(authenticationStore, [
      'loggedIn',
      'loggedInUser',
      'loggedInAccount',
      'allowChangeAccount',
      'licensedFeatures',
      'isAdminAccount',
      'expiresNext',
      'showTrialBar'
    ]),

    ...mapGetters({
      settings: 'systemSettings/settings',
      onPremises: 'onpremises'
    }),
    showLicenseAlert() {
      return this.onPremises ? false : this.expiresNext;
    },
    getAlertText(): string {
      return this.expiresNext
        ? i18n.t('app.licenseExpiresSoon', {
          msg: this.expiresNext.name,
          msg2: this.expiresNext.activationCode,
          msg3: this.leftDaysExpiresNext,
          msg4: dateFormatter.dateWithoutTime(this.expiresNext.expiresAt)
        })
        : i18n.t('app.trialPeriod', {
          msg: this.leftDaysTrialLicense,
          msg2: dateFormatter.dateWithoutTime(
            this.loggedInAccount.activeLicenses[0].expiresAt
          )
        });
    },
    userMenuWithLogoutCallback() {
      return this.userMenu(this.doLogout.bind(this));
    },
    notError(): boolean {
      const routeName = this.$route.name as string;
      return !(routeName?.includes('500') || routeName?.includes('404'));
    },
    leftDaysExpiresNext(): number {
      const expires = new Date(this.expiresNext.expiresAt);
      const now = new Date();
      return Math.floor(
        (expires.getTime() - now.getTime()) / (1000 * 3600 * 24)
      );
    },
    leftDaysTrialLicense(): number {
      const expires = new Date(
        this.loggedInAccount.activeLicenses[0].expiresAt
      );
      const now = new Date();
      return Math.floor(
        (expires.getTime() - now.getTime()) / (1000 * 3600 * 24)
      );
    }
  },
  created() {
    this.loadSettingsWhenLoggedIn();
  },
  methods: {
    ...mapActionsPinia(authenticationStore, ['logout']),
    ...mapActions({
      loadSettings: 'systemSettings/load'
    }),
    loadSettingsWhenLoggedIn() {
      if (this.loggedIn && this.notError) {
        void this.loadSettings();
      }
    },
    doLogout(): void {
      void this.logout();
    },
    openChangeAccountDialog(): void {
      this.showChangeAccountDialog = true;
    }
  }
});
</script>
<style lang="scss" scoped>
@import '@/styles/colors.scss';

.toolbar-title {
  color: white !important;
  margin-top: -4px;
}

.user-name-and-organisation {
  font-size: 13px;
  color: white !important;
  padding-left: 12px;
  padding-right: 12px;
  max-width: 320px;
  text-align: left !important;
  overflow: hidden !important;
}
.change-account-button:hover {
  background-color: $darker-grey !important;
}
</style>
